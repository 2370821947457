<template>
	<div
		class="expandable-container"
		:class="{
			'is-active': expanded,
			'expandable-container--overflow' : transitioning || !expanded,
			'hide': !expanded && !transitioning
		}"
		:style="`max-height: ${height};`"
		:aria-hidden="!expanded"
		@transitionstart="transitionStart"
		@transitionend="transitionEnd"
	>
		<div ref="content">
			<slot />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CExpandable',
		props: {
			expanded: {
				type: Boolean,
				required: true,
				default: false
			}
		},
		data() {
			return {
				height: '',
				transitioning: false
			};
		},
		watch: {
			expanded() {
				this.transitioning = true;
				this.determineHeight();
			}
		},
		async mounted() {
			await this.$nextTick();
			if (!this.expanded)
				this.height = 0;
		},
		methods: {
			determineHeight() {
				// If going to an expanded state, set max-height from nothing to zero so it can transition
				// to the content's height. If going to a contracted state, the reverse should happen.
				if (this.expanded) {
					this.height = 0;
					setTimeout(()=>{
						this.height = this.$refs.content.clientHeight + 'px';
					});
				}
				else {
					this.height = this.$refs.content.clientHeight + 'px';
					setTimeout(()=>{
						this.height = 0;
					});
				}
			},
			transitionEnd(e) {
				if (e.propertyName === 'max-height' && e.srcElement === this.$el)
					this.transitioning = false;
				if (this.expanded)
					this.height = '';
			},
			transitionStart(e) {
				if (e.propertyName === 'max-height' && e.srcElement === this.$el)
					this.transitioning = true;
			}
		}
	};
</script>

<style lang="scss">
	.expandable-container {
		transition: max-height .3s;

		&--overflow {
			overflow-y: hidden;
		}
	}
</style>